/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import './login.css'
import HeaderSecond from '../../components/header/HeaderSecond'
import FooterSecond from '../../components/footer/FooterSecond'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {login} from '../../actions/authActions'

const Login = () => {
	const dispatch = useDispatch()
	let history = useHistory()
	const auth = useSelector((state) => state.auth)
	const {isAuthenticated, isTestTaken} = auth

	// console.log(isAuthenticated)

	if (isAuthenticated && !isTestTaken) {
		history.push('/sat/welcome')
	}

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	})
	const {email, password} = formData

	const onChangeHandler = (e) => {
		setFormData({...formData, [e.target.name]: e.target.value})
	}

	const onSubmitHandler = (e) => {
		e.preventDefault()
		dispatch(login(email, password))
	}

	return (
		<div className='wrap-thank'>
			<HeaderSecond />
			<Container fluid>
				<Row className='pt-5 pb-5 mt-5 mb-5 lform'>
					<Col>
						<Row className='bg-white color-black pt-5 pb-5' style={{
						width: '750px',
						margin: 'auto',
						maxWidth: '100%',
						borderRadius: '5px'
					}}>
							<Col className='pl-5 pr-5'>
								<h2 className='text-center'>
									<img
										src='https://collegepass.s3.ap-south-1.amazonaws.com/BHolo.jpg'
										alt='Logo'
									/>
								</h2>
								<h2 className='color-black text-center pb-5 lheading'>
									Sign in with your CollegePass ID
								</h2>
								<Form style={{
									maxWidth: '100%',
									width: '50%',
									margin: 'auto'
								}}>
									<Form.Group controlId='formBasicEmail'>
										<Form.Control
											name='email'
											onChange={onChangeHandler}
											value={email}
											type='email'
											placeholder='Enter email'
										/>
									</Form.Group>

									<Form.Group controlId='formBasicPassword'>
										<Form.Control
											name='password'
											onChange={onChangeHandler}
											value={password}
											type='password'
											placeholder='Password'
										/>
									</Form.Group>

									<Button
										variant='primary'
										onClick={onSubmitHandler}
										className='mt-3 width-100 color-white border-none'
									>
										Login
									</Button>
								</Form>
								<p className='pt-5 text-center'>
									<a href='/registration' style={{
										fontSize: '17px',
										textDecoration: 'none',
										color: 'rgb(0, 102, 204)',
										paddingTop: '0px',
									}}>Create New CollegePass ID</a>
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<FooterSecond />
		</div>
	)
}

export default Login
