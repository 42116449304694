import {
	LOGIN_FAILURE,
	LOGIN_SUCCESS,
	LOGOUT,
	AUTH_ERROR,
	USER_LOADED,
	USER_RELOAD,
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
} from '../constants/authConstants'

import {reactLocalStorage} from 'reactjs-localstorage'
import {token, email, isTestTaken} from '../config/localStorage'

const initialState = {
	token: reactLocalStorage.get(token, null, true),
	isAuthenticated: null,
	loading: true,
	email: reactLocalStorage.get(email, null, true),
	isTestTaken: reactLocalStorage.get(isTestTaken, true, true),
}

export const authReducer = (state = initialState, action) => {
	const {type, payload} = action
	switch (type) {
		case USER_RELOAD: {
			return {
				...state,
				token: payload.token,
				isAuthenticated: payload.isAuthenticated,
				loading: payload.loading,
				email: payload.email,
				isTestTaken: payload.isTestTaken,
				// testDetails: payload.testDetails,
			}
		}
		case LOGIN_SUCCESS:
		case USER_LOADED:
		case REGISTER_SUCCESS:
			return {
				...state,
				token: payload.token,
				isAuthenticated: payload.isAuthenticated,
				loading: payload.loading,
				email: payload.email,
				isTestTaken: payload.isTestTaken,
				// testDetails: payload.testDetails,
			}
		case AUTH_ERROR:
		case LOGIN_FAILURE:
		case REGISTER_FAILURE:
		case LOGOUT:
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				email: null,
				isTestTaken: true,
				// testDetails: {},
			}
		default:
			return state
	}
}
