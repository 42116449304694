import {Container, Row, Col} from 'react-bootstrap'
import './error.css'
import HeaderSecond from '../../components/header/HeaderSecond'
import FooterSecond from '../../components/footer/FooterSecond'

const ErrorSec = () => {
	return (
		<div className='wrap-thank'>
			<HeaderSecond />
			<div className='bg-img'>
				<Container fluid>
					<Container className='mt-2 mb-2'>
						<Row className='pt-2 pb-2'>
							<Col lg={2} md={2} sm={12} xs={12}></Col>
							<Col
								lg={8}
								md={8}
								sm={12}
								xs={12}
								className='text-left color-white'
							>
								<Row className='mt-5 mb-5'>
									<div style={{width: '100%'}} className=''>
										<Row>
											<Col className='error-sec'>
												<h2
													className='text-center'
													style={{
														fontWeight: '600',
														fontSize: '26px',
													}}
												>
													Oops! You ran out of oxygen.
												</h2>
												<p
													className='text-center'
													style={{
														marginBottom: '30px',
													}}
												>
													The page you&apos;re looking for is now beyond our
													reach.
												</p>
												<div className='text-center'>
													<img src='/404.png' />
												</div>
												<p
													className='text-center'
													style={{
														marginTop: '30px',
													}}
												>
													Let&apos;s get you...
												</p>
												<p
													className='text-center'
													style={{
														marginTop: '30px',
													}}
												>
													<a
														href='http://collegepass.org/'
														className='button-cp'
													>
														Back Home Page
													</a>
												</p>
											</Col>
										</Row>
									</div>
								</Row>
							</Col>
							<Col lg={2} md={2} sm={12} xs={12}></Col>
						</Row>
					</Container>
				</Container>
			</div>
			<FooterSecond />
		</div>
	)
}

export default ErrorSec
