import React from 'react';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
//import Offcanvas from 'react-bootstrap/Offcanvas';
import './header.css';

const HeaderSecond = () => {

    

  return (
    <>
    <Container fluid className="pt-3 pb-3 pl-5 pr-5">
        <Row>
            <Col className="ug-header ml-5 mr-5">
              {/*<Row>
                  <Col className='for-mobile-menu'>
                      <Navbar bg="black" expand={"sm, md, lg, xl, xxl"}>
                          <Container className='p-0'>
                              <Navbar.Brand href="/">
                                  <p className="ugLogo">
                                      <Image
                                      src="/logo/cp_text_logo.png"
                                      alt="Text Logo"
                                      width="180"
                                      height="52"
                                      className="footerLogo"
                                      />
                                  </p>
                              </Navbar.Brand>
                              <Navbar.Toggle />
                              <Navbar.Offcanvas placement="end">
                                  <Offcanvas.Header closeButton>
                                      <Offcanvas.Title className='visibility-hidden'>
                                          CollegePass
                                      </Offcanvas.Title>
                                  </Offcanvas.Header>
                                  <Offcanvas.Body>
                                      <Nav className="justify-content-end flex-grow-1">
                                          <Nav.Link href="/collegepass-plus" id="" style={{
                                              marginRight: '2rem'
                                          }}>CollegePass+</Nav.Link>
                                          <Nav.Link href="/ivy-league-undergraduate-admissions" id="" style={{
                                              marginRight: '2rem'
                                          }}>Undergraduate Admissions</Nav.Link>
                                          <Nav.Link href="/ms-admissions" id="" style={{
                                              marginRight: '2rem'
                                          }}>MS/MBA Admissions</Nav.Link>
                                          <Nav.Link href="/sat-tutoring" id="" style={{
                                              marginRight: '2rem'
                                          }}>SAT Tutoring</Nav.Link>
                                          <Nav.Link href="/ib-tutoring" id="" style={{
                                              marginRight: '2rem'
                                          }}>IB/IGCSE Tutoring</Nav.Link>
                                          <Nav.Link href="" id="login" onClick={()=>{handleShowLogin()}}>
                                              <p className="Login">Login</p></Nav.Link>
                                          </Nav>
                                  </Offcanvas.Body>
                              </Navbar.Offcanvas>
                          </Container>
                      </Navbar>
                  </Col>
              </Row>*/}
              <Row>
                  <Col className='for-desktop-menu'>
                      <Navbar bg="black" expand="lg">
                              <Navbar.Brand href="/">
                                  <p className="ug-logo">
                                      <Image
                                      src="/logo/cp_text_logo.png"
                                      alt="Text Logo"
                                      width="180"
                                      height="52"
                                      // className={styles.footerLogo}
                                      />
                                  </p>
                              </Navbar.Brand>
                              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                              <Navbar.Collapse className="custom-navbar">
                                  <Nav className="ms-auto">
                                      <Nav.Link href="/collegepass-plus" id="ug-menu-adm-adv" style={{
                                          marginRight: '2rem'
                                      }}>CollegePass+</Nav.Link>
                                          <NavDropdown title="Admissions Advising" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                              <NavDropdown.Item href="/ivy-league-undergraduate-admissions">UNDERGRADUATE ADMISSIONS</NavDropdown.Item>
                                              <NavDropdown.Item href="/ms-admissions">
                                                  MS/MBA ADMISSIONS
                                              </NavDropdown.Item>
                                          </NavDropdown>
                                          <NavDropdown title="Test Prep" className="custom-css-nav" renderMenuOnMount={true}>
                                              <NavDropdown.Item href="/sat-tutoring">SAT Tutoring</NavDropdown.Item>
                                              <NavDropdown.Item href="/ib-tutoring">
                                                  IB/IGCSE Tutoring
                                              </NavDropdown.Item>
                                          </NavDropdown>
                                          
                                  </Nav>
                              </Navbar.Collapse>
                      </Navbar>
                  </Col>
              </Row>
            </Col>
        </Row>
    </Container>
<Container fluid className="header-stripNew">
  <Row>
      <Col className="ug-strip">
          <p>Every CollegePass student has received a minimum of 3 admission offers in 2022.<a href="/success-stories">Success stories <i className="fa fa-external-link" aria-hidden="true"></i></a></p>
      </Col>
  </Row>
</Container>
</>
  );
}

export default HeaderSecond;
