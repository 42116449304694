/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {useEffect, useState} from 'react'
import {Row, Col, Form} from 'react-bootstrap'
import axios from 'axios'
import './options.css'
import Subjectiveoptions from './subjectiveOptions'
import ConfirmModal from '../modal/ConfirmModal'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'

import {storeUserAnswers} from '../../config/api'

const ObjectiveOptions = ({testdata, sectionname, nextSection, timerout, spentSec}) => {
	let history = useHistory()

	const auth = useSelector((state) => state.auth)
	const {email} = auth
	const {testDetails} = useSelector((state) => state.test)

	let OptionLists = ''

	const [questData, setQuestData] = useState([])

	const [ObjectiveAnswers, setObjectiveAnswers] = useState([])

	const [SubjectiveAnswers, setSubjectiveAnswers] = useState([])

	// const ObjectiveAnswers = [];

	// const SubjectiveAnswers = [];

	const [show, setShow] = useState(false)

	const handleClose = (e) => {
		e.preventDefault()
		setShow(false)
	}
	const handleShow = (e) => {
		e.preventDefault()
		setShow(true)
	}

	useEffect(() => {
		const getCPQuestions = async (testId, sectionId) => {
			const config = {
				headers: {Authorization: `Bearer ${localStorage.token}`},
			}
			try {
				const result = await axios.get(
					`https://api.collegepass.org/tp/v1/getCPQuestions/${testId}/${sectionId}`,
					config
				)

				// const result = await axios.get(
				// 	`http://localhost:4000/tp/v1/getCPQuestions/${testId}/${sectionId}`,
				// 	config
				// )
				if (result.status === 200) setQuestData(result.data.message)
			} catch (err) {
				console.error(err)
			}
		}

		const testId = testdata.testID

		const sectionId = testdata.Sections.indexOf(sectionname) + 1

		if (testId && sectionId) getCPQuestions(testId, sectionId)

		return () => {}
	}, [])

	function findWithAttr(array, attr, value) {
		for (var i = 0; i < array.length; i += 1) {
			
			if (array[i][attr] == value) {
				return i
			}
		}
		return -1
	}

	const onOptionChange = async (
		value,
		question_no,
		optionType = 'OBJECTIVE'
	) => {
		if (optionType === 'OBJECTIVE') {
			let ans = {
				"question_no": parseInt(question_no),
				value,
			}
			//const ObjAnswers =[];
			let index = findWithAttr(ObjectiveAnswers, 'question_no', question_no)
			// console.log('ObjectiveAnswers', ObjectiveAnswers)
			// console.log('index', index)
			if (index >= 0) {
				ObjectiveAnswers[index] = ans
			} else {
				//ObjAnswers.push(ans);
				setObjectiveAnswers([...ObjectiveAnswers, ans])
			}

			console.log('ObjectiveAnswers 1', ObjectiveAnswers)
		}
	}

	const finalOnchange = async (value1, value2, value3, value4, question_no) => {
		if (value1 || value2 || value3 || value4) {
			let value = `${value1}${value2}${value3}${value4}`

			let ans = {
				"question_no": parseInt(question_no),
				value,
			}

			let index = findWithAttr(SubjectiveAnswers, 'question_no', question_no)
			console.log('SubjectiveAnswers', SubjectiveAnswers)
			console.log('index', index)
			if (index >= 0) {
				SubjectiveAnswers[index] = ans
			} else {
				setSubjectiveAnswers([...SubjectiveAnswers, ans])
			}
		}
	}

	const onsubmit = async (e) => {
		e.preventDefault()

		console.log('SubjectiveAnswers', SubjectiveAnswers)

		console.log('ObjectiveAnswers', ObjectiveAnswers)

		await callAnswerApi();

		history.push(nextSection)
		// window.location.href = nextSection;
	}

	const findQuestionsAns = async(_finalAnswers,index) => {

		for (var i = 0; i < _finalAnswers.length; i += 1) {
			if (_finalAnswers[i]["question_no"] === index) {
				let ans = _finalAnswers[i]["value"]
				return {
					i,
					ans
				}
			}
		}

		return -1

	}

	function removeFalsyValues(array) {
		if (array.length > 0) return array.filter(e => !!e);
		return false;
	}
	const callAnswerApi = async () =>{
		let finalAnswers = [...SubjectiveAnswers, ...ObjectiveAnswers]
		let section_id = 1
		let section_db_id = testDetails.sectionDBId[0]
		let totalSpentSec = 0;
		const totalAnsList = [];

		if (sectionname === 'cp-reading') {
			section_id = 1
			section_db_id = testDetails.sectionDBId[0]
			totalSpentSec = 65*60 - spentSec
		}
		if (sectionname === 'cp-writing') {
			section_id = 2
			section_db_id = testDetails.sectionDBId[1]
			totalSpentSec = (35*60) - parseInt(spentSec)
		}
		if (sectionname === 'cp-math-no-calci') {
			section_id = 3
			section_db_id = testDetails.sectionDBId[2]
			totalSpentSec = (25*60) - parseInt(spentSec)
		}
		if (sectionname === 'cp-math') {
			section_id = 4
			section_db_id = testDetails.sectionDBId[3]
			totalSpentSec = (55*60) - parseInt(spentSec)
		}
		let questCount = 0;
		if(section_id === 1 ){
			questCount= 52;
		} else if(section_id === 2 ){
			questCount= 44;
		} else if(section_id === 3 ){
			questCount= 20;
		}else if(section_id === 4 ){
			questCount= 38;
		}

		console.log({finalAnswers});

		for (let index = 1; index <= questCount; index++) {
			console.log({index});
			
			let {i, ans } = await findQuestionsAns(finalAnswers,index);

			console.log("index got",i);

			console.log("data ans",ans);
			if (i >= 0) {
				totalAnsList[index] = {
					"question_no": index,
					"value":ans
				}
			} else {
				totalAnsList[index] ={
					"question_no": index,
					"value":""
				}
			}
		}
		console.log(removeFalsyValues(totalAnsList));
		//call the api
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			}
			let body = {
				test_id: 1,
				section_id,
				test_db_id: testDetails.mainDBId,
				section_db_id,
				user_email: email,
				answer_array: removeFalsyValues(totalAnsList),
				final_submission: true,
				timer_value:totalSpentSec
			}
			console.log(body);

			const res = await axios.post(storeUserAnswers, body, config)
			console.log(res)
			//move to the next section
			
		} catch (err) {
			console.log(err)
		}

	}

	useEffect(() => {

		//console.log({timerout});

		if(timerout === true){
			
			callAnswerApi();

			history.push(nextSection)
		}

		//var updateAns = setInterval(callAnswerApi,3000)
		
		return () => {
			//clearInterval(updateAns);
		}
	}, [callAnswerApi,timerout])

	OptionLists = questData.map((ques) => {
		if (ques.ANS_TYPE === 'OBJECTIVE') {
			return (
				<Row key={ques.QUESTION_NO}>
					<Col>
						<h5>{ques.QUESTION_NO}</h5>
					</Col>
					<Col>
						<Form.Check
							type='radio'
							label='A'
							value='A'
							name={`formRadios_${ques.QUESTION_NO}`}
							id={`Radios1_${ques.QUESTION_NO}`}
							className='a'
							onChange={(e) => {
								onOptionChange(e.target.value, ques.QUESTION_NO)
							}}
						/>
					</Col>
					<Col>
						<Form.Check
							type='radio'
							label='B'
							value='B'
							name={`formRadios_${ques.QUESTION_NO}`}
							id={`Radios2_${ques.QUESTION_NO}`}
							className='b'
							onChange={(e) => {
								onOptionChange(e.target.value, ques.QUESTION_NO)
							}}
						/>
					</Col>
					<Col>
						<Form.Check
							type='radio'
							label='C'
							value='C'
							name={`formRadios_${ques.QUESTION_NO}`}
							id={`Radios3_${ques.QUESTION_NO}`}
							className='c'
							onChange={(e) => {
								onOptionChange(e.target.value, ques.QUESTION_NO)
							}}
						/>
					</Col>
					<Col>
						<Form.Check
							type='radio'
							label='D'
							value='D'
							name={`formRadios_${ques.QUESTION_NO}`}
							id={`Radios4_${ques.QUESTION_NO}`}
							className='d'
							onChange={(e) => {
								onOptionChange(e.target.value, ques.QUESTION_NO)
							}}
						/>
					</Col>
				</Row>
			)
		}

		return (
			<Subjectiveoptions
				ques={ques}
				onOptionChange={onOptionChange}
				finalOnchange={finalOnchange}
				key={ques.QUESTION_NO}
			/>
		)
	})

	return (
		<Col className='radio-options-bg custom-radio-css'>
			<Form onSubmit={onsubmit} id='my-form'>
				<Row>
					<Col className='text-center border-bottom'>
						<h1 className='color-black option-heading pt-2 pb-2'>
							YOUR ANSWERS
						</h1>
					</Col>
				</Row>
				{OptionLists}
				<Row>
					<Col className='continue-button'>
						<button onClick={handleShow} variant='primary' className=''>
							Submit Answers
						</button>
					</Col>
				</Row>
				<ConfirmModal
					show={show}
					handleClose={handleClose}
					onsubmit={onsubmit}
				/>
			</Form>
		</Col>
	)
}
export default ObjectiveOptions
