import axios from 'axios'
import {
	INITIALISE_TEST_START,
	INITIALISE_TEST_COMPLETED,
	INITIALISE_TEST_ERROR,
} from '../constants/testConstants'

import {initialiseTestAPI} from '../config/api'

import {reactLocalStorage} from 'reactjs-localstorage'
import {token, testDetails} from '../config/localStorage'

export const initialiseTest = (userEmail, testID) => async (dispatch) => {
	let payload = {
		testDetails: {},
	}
	dispatch({
		type: INITIALISE_TEST_START,
		payload,
	})

	try {
		const config = {
			headers: {
				Authorization: `Bearer ${reactLocalStorage.get(token, null, true)}`,
				'Content-Type': 'application/json',
			},
		}
		const body = {
			user_email: userEmail,
			test_id: testID,
		}
		const {data} = await axios.post(initialiseTestAPI, body, config)

		if (data.status) {
			reactLocalStorage.setObject(testDetails, data.data)

			payload = {
				testDetails: data.data,
			}
			dispatch({
				type: INITIALISE_TEST_COMPLETED,
				payload,
			})
		} else {
			payload = {
				testDetails: {},
			}
			reactLocalStorage.remove(testDetails)
			dispatch({
				type: INITIALISE_TEST_ERROR,
				payload,
			})
		}
	} catch (err) {
		payload = {
			testDetails: {},
		}
		reactLocalStorage.remove(testDetails)
		dispatch({
			type: INITIALISE_TEST_ERROR,
			payload,
		})
	}
}
