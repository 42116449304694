/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Routes from './routes/Routes'
import {useDispatch, useSelector} from 'react-redux'
import {reloadAuthDetails} from './actions/authActions'
import Alert from './components/alert/Alert'

import './App.css'

function App() {
	const dispatch = useDispatch()
	const auth = useSelector((state) => state.auth)
	const {isAuthenticated} = auth

	useEffect(() => {
		if (!isAuthenticated) {
			dispatch(reloadAuthDetails())
		}
	}, [dispatch, isAuthenticated])

	return (
		<div className='main-area-wrap'>
			<Router>
				<Route component={Routes} />
				<Alert></Alert>
			</Router>
		</div>
	)
}

export default App
