/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap'
import './header.css'
import {TestDetails} from '../../config/testDetails'

// eslint-disable-next-line react/prop-types
const Header = ({
	timerSection, 
	testName,
	headertype,
	setMinutes,
	minutes,
	seconds,
	myInterval
}) => {
	let Timer = null
	let testTime = null

	const [sectionName, setSectionName] = useState('CollegePass Test')

	const [sectionTimer, setSectionTimer] = useState(0)

	const [instructionTimer, setInstructionTimer] = useState(0)

	const [questionCount, setQuestionCount] = useState(0)

	// const [minutes, setMinutes] = useState(0)

	// const [seconds, setSeconds] = useState(0)

	useEffect(() => {
		const getSectionDetails = async (timerSection, testName) => {
			var testKey = Object.keys(TestDetails).find((key) => key === testName)

			let testData = TestDetails[testKey]

			setSectionName(testData.SectionsName[timerSection])

			setSectionTimer(testData.SectionTimers[timerSection].section)

			setInstructionTimer(testData.SectionTimers[timerSection].instruction)

			setQuestionCount(testData.SectionQuestionCount[timerSection])

			if (headertype === 'section')
				setMinutes(testData.SectionTimers[timerSection].section)
			else setMinutes(testData.SectionTimers[timerSection].instruction)
		}
		if (timerSection && testName) getSectionDetails(timerSection, testName)

		return () => {}
	}, [timerSection, testName])

	useEffect(() => {
		// let myInterval = setInterval(() => {
		// 	if (seconds > 0) {
		// 		setSeconds(seconds - 1)
		// 	}
		// 	if (seconds === 0) {
		// 		if (minutes === 0) {
		// 			clearInterval(myInterval)
		// 		} else {
		// 			setMinutes(minutes - 1)
		// 			setSeconds(59)
		// 		}
		// 	}
		// }, 1000)
		return () => {
			clearInterval(myInterval)
		}
	})

	let secondClock = seconds < 10 ? `0${seconds}` : seconds

	let timerClock =
		minutes === 0 && seconds === 0 ? null : minutes + ':' + secondClock
	if (minutes > 0 || seconds > 0){
		Timer = (
			<h5 className='width-100 d-inline-flex float-right pr-4 pt-3 pb-2 color-white text-center'>
				TIME REMAINING :{' '}
				<span className='countdown-timer'>{timerClock} Mins</span>
			</h5>
		)
	}

	if (headertype === 'section')
		testTime = (
			<Col
				lg={10}
				md={10}
				sm={10}
				xs={10}
				className='pr-4 color-white float-left'
			>
				<h2 className='english-test-header pt-3 pb-2'>
					{sectionName} :{' '}
					<span>
						{' '}
						{headertype === 'section' ? sectionTimer : instructionTimer}:00
						Minutes - {questionCount} Questions
					</span>
				</h2>
			</Col>
		)

	return (
		<div className='wrap-header'>
			<div className='header-mn'>
				<Container fluid>
					<Row>
						<Col className='header bg-black'>
							<Row>
								<Col lg={9} md={7} sm={12} xs={12}>
									<Row>
										<Col lg={2} md={2} sm={2} xs={2} className='logo'>
											<Image
												src='https://collegepass-logos.s3.ap-south-1.amazonaws.com/holo.png'
												alt='CP-Logo'
												className='pt-2'
											/>
										</Col>
										{testTime}
									</Row>
								</Col>
								<Col lg={3} md={5} sm={12} xs={12}>
									<Row>
										<Col className='timer'>{Timer}</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	)
}

export default Header
