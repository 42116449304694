/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {TestDetails} from '../../config/testDetails';

const PdfReader = ({pdfFor, testName}) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber] = useState(1);
    const [pdfScale, setScale] = useState(1.7);
    const increaseScale = () => setScale(( pdfScale ) =>  Number((pdfScale + 0.1).toFixed(1)) );
    const decreaseScale = () => setScale(( pdfScale ) =>  Number((pdfScale - 0.1).toFixed(1)) );
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [sectionPdfUrl, setSectionPdfUrl] = useState(null);

    useEffect(() => {

        const getSectionPdf = TestDetails[testName].SectionsPdf[pdfFor] || null;

        setSectionPdfUrl(getSectionPdf);
        
        return () => {}
    }, [pdfFor,testName])
   
    return (
        <div>
            <p className="scale-btns">
                <button onClick={decreaseScale}>-</button>
                <span>Zoom : {pdfScale}</span>
                <button onClick={increaseScale}>+</button>
            </p>
            <Document
            file={sectionPdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading = "Please wait!!"
            >
            {
                Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={pdfScale}
                    />
                  ),
                )
              }
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
        </div>
    )
}
export default PdfReader;