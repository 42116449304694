import React, { Fragment } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import './footer.css';


const FooterSecond = () => {

  return (
    <Fragment>
    <Container fluid style={{
      backgroundColor: 'rgb(22, 21, 23)',
      color: 'rgb(255, 255, 255)'
      }} className="pl-5 pr-5">
        <Row>
            <Col className="ug-top-footer pl-5 pr-5">
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Row className="ug-footer-logo-holo">
                            <Col>
                                <Image
                                    width="45"
                                    height="45"
                                    src="/holo.png"
                                    alt="Holo"
                                    // className={styles.ugFooterHolo}
                                />
                                <Image
                                    src="/logo/cp_text_logo.png"
                                    alt="Text Logo"
                                    width="150"
                                    height="43"
                                    // className={styles.ugFooterLogo}
                                />
                            </Col>
                        </Row>
                        {/*<Row className={styles.ugbrandFooter}>
                            <Col>
                                <Image
                                    width="405"
                                    height="67"
                                    src="/security-logos.jpg"
                                    alt="Holo"
                                    className={styles.ugFooterBrand}
                                />
                            </Col>
                        </Row>*/}
                        <Row className="ug-logos-section-text-top">
                            <Col>
                                <h6>Global Admissions Advising Platform</h6>
                            </Col>
                        </Row>
                        <Row className="ug-logos-section-text-bottom">
                            <Col>
                                <p>Learn how CollegePass can help you get into the Top Colleges/Universities in the USA, UK, Canada and other countries.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Row className="ug-footer-menu">
                            <Col className="footer-about-list">
                                <p>Explore</p>
                                <ul>
                                    <li><a href="/pricing">Pricing</a></li>
                                    <li><a href="/success-stories">Success Stories</a></li>
                                    <li><a href="/sitemap.xml">Sitemap</a></li>
                                </ul>
                            </Col>
                            <Col>
                                <p>About</p>
                                <ul>
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="https://blog.collegepass.org/">Blog</a></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="ug-footer-menu">
                            <Col className="footer-social-media">
                                <p>Social</p>
                                <ul>
                                    <li><a href="https://twitter.com/CollegePassOne?s=09" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                                    <li><a href="https://www.instagram.com/_collegepass/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                                    <li><a href="https://www.facebook.com/CollegePassPremiere/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                                    <li><a href="https://www.linkedin.com/company/collegepass/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                                    <li><a href="https://www.youtube.com/c/CollegePass" target="_blank" rel="noopener noreferrer">YouTube</a></li>
                                </ul>
                            </Col>
                            <Col>
                                <p>Download</p>
                                    <ul>
                                        <li>
                                        <a href="https://apple.co/3M1IDuT" target="_blank" rel="noopener noreferrer">
                                            <Image
                                                width="190"
                                                height="56"
                                                src="/Apple_Icon 02_01.png"
                                                alt="Holo"
                                                // className={styles.ugFooterBrand}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://bit.ly/3trJ3m3" target="_blank" rel="noopener noreferrer">
                                            <Image
                                                width="190"
                                                height="56"
                                                src="/Google_Play_Icon 02_01.png"
                                                alt="Holo"
                                                // className={styles.ugFooterBrand}
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
  </Container>
  <Container fluid style={{
      backgroundColor: 'rgb(22, 21, 23)',
      color: 'rgb(255, 255, 255)'
    }} className="pl-5 pr-5">
          <Row>
              <Col className="ug-bottom-footer pl-5 pr-5">
                  <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <Row>
                              <Col className="ug-copy-right">
                                  <p>Copyright © 2021-22 Empagnie PTE Limited.</p>
                              </Col>
                          </Row>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                          <Row>
                              <Col className="ug-copy-right">
                                  <Row>
                                      <Col className="ug-copy-right-r">
                                          <a href="/privacy">Privacy policy</a>
                                          <a href="/terms" style={{
                                              border: 'none'
                                          }}>Terms and conditions</a>
                                      </Col>
                                  </Row>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </Col>
          </Row>
  </Container>
  </Fragment>
  );
}

export default FooterSecond;
