import React, {Fragment} from 'react'
import {Route, Switch} from 'react-router-dom'
import ThankYou from '../pages/thankYou/ThankYou'
import Result from '../pages/result/Result'
import TestInstructions from '../pages/testPortal/TestInstructions'
import Section from '../pages/testPortal/Section'
import Welcome from '../components/instruction/Welcome'
import TestDone from '../pages/error/TestDone'
import ErrorSec from '../pages/error/ErrorSec'
import Login from '../pages/login/Login'
import Registration from '../pages/login/Registration'
import Landing from '../pages/landingInfo/LandingInfo'
import EmailTemplate from '../pages/result/EmailTemplate'
import EmailTemplateInternal from '../pages/result/EmailTemplateInternal'
import PrivateRoute from './PrivateRoute'
import LoggedRoute from './LoggedRoute'

const Routes = () => {
	return (
		<Fragment>
			<Switch onUpdate={() => window.scrollTo(0, 0)}>
				<Route exact path='/' component={Login} />
				<Route exact path='/login' component={Login} />
				<Route exact path='/registration' component={Registration} />
				<Route exact path='/landing/:landingInfo' component={Landing} />
				<PrivateRoute
					exact
					path='/instructions/:testName/:sectionName'
					component={TestInstructions}
				/>
				<PrivateRoute
					exact
					path='/cp-test/:CPtestName/:CPsectionName'
					component={Section}
				/>
				<PrivateRoute
					exact
					path='/thank-you/:CPtestName'
					component={ThankYou}
				/>
				<PrivateRoute exact path='/result/:CPtestName' component={Result} />
				<PrivateRoute exact path='/:CPtestName/welcome' component={Welcome} />
				<Route exact path='/test/done' component={TestDone} />
				<LoggedRoute exact path='/email/:testID' component={EmailTemplate} />
				<LoggedRoute exact path='/internal-email-collegepass/:testID/:userEmail' component={EmailTemplateInternal} />

				<Route path='*' component={ErrorSec} />
			</Switch>
		</Fragment>
	)
}

export default Routes

//Added for nothing
