/* eslint-disable react/prop-types */
import { Container, Row, Col } from 'react-bootstrap';
import './test.css';
import PdfReader from '../pdfReader/pdfReader';
import Options from '../../components/option/Options';

const Test = ({pageOptionCp, testName, timerout, spentSec}) => {


    let optionSecFor = <Col lg={3} md={3} sm={12} xs={12}>
                            <Row>
                                <Col className="option-sec pr-0 pl-0">
                                    <Options optionFor={pageOptionCp} testName= {testName} timerout={timerout} spentSec={spentSec}/>
                                </Col>
                            </Row>
                        </Col>

    let PdfSection = <Col lg={9} md={9} sm={12} xs={12}>
                        <Row>
                            <Col className="pdf-sec">
                                <PdfReader pdfFor={pageOptionCp} testName= {testName}/>
                            </Col>
                        </Row>
                    </Col>

    return (
        <div className="wrap-main-section">
            <Container fluid> 
                    <Container fluid className="mb-0 pl-0 pr-0">
                        <Row>
                            <Col className="area-sec">
                                <Row>
                                    {PdfSection}
                                    {optionSecFor}
                                </Row>
                            </Col> 
                        </Row>
                    </Container>
            </Container>
        </div>
    );
}

export default Test;
