import { Container, Row, Col } from 'react-bootstrap';
import './error.css';
import HeaderSecond from '../../components/header/HeaderSecond';
import FooterSecond from '../../components/footer/FooterSecond';

const TestDone = () => {


  return (
        <div className="wrap-thank">
            <HeaderSecond />
            <div className="bg-img">
                <Container fluid>
                    <Row className="pt-5 pb-5">
                        <Col lg={2} md={2} sm={12} xs={12}></Col>
                        <Col lg={8} md={8} sm={12} xs={12} className="text-left color-white">
                            <Row className="mt-5 mb-5">
                                <div style={{width: '100%'}} className="">
                                    <Col className="error-sec">
                                    <div className="text-center mb-3 lgodone">
                                        <img
                                        src='/holo.png'
                                        alt='Logo'
                                    /></div>
                                        <h2 className="text-center" style={{
                                            fontWeight: '600',
                                            fontSize: '26px'
                                        }}>Looks like you have already taken the test</h2>
                                        
                                        <p className="text-center" style={{
                                            marginTop: '45px'
                                        }}><a href="http://collegepass.org/" className="button-cp">Go Back Home</a></p>
                                        
                                        <p className="text-center mt-5">Incase you have any questions, write to us at <a href="mailto:support@collegepass.org" target="_blank" rel="noreferrer" className="color-gold support">support@collegepass.org</a></p>

                                    </Col>
                                </div>
                            </Row>
                        </Col>
                        <Col lg={2} md={2} sm={12} xs={12}></Col>
                    </Row>
                </Container>
            </div>    
            <FooterSecond />
        </div>    
  );
}

export default TestDone;
