/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Table} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './result.css'
import HeaderSecond from '../../components/header/HeaderSecond'
import FooterSecond from '../../components/footer/FooterSecond'
import axios from 'axios'
import {useHistory} from 'react-router-dom'

import {getFinalResultsAPI, requestFullReport} from '../../config/api'

import {useSelector} from 'react-redux'

const Result = ({match}) => {
	let history = useHistory()

	const {testDetails} = useSelector((state) => state.test)
	const user = useSelector((state) => state.auth)

	const [engRowSpan, setEngRowSpan] = useState(1)
	const [mathRowSpan, setMathRowSpan] = useState(1)

	const [reading, setReading] = useState({
		your: 0,
		max: 0,
		percentile: 100,
	})
	const [writing, setWriting] = useState({
		your: 0,
		max: 0,
		percentile: 100,
	})
	const [math, setMath] = useState({
		your: 0,
		max: 0,
		percentile: 100,
	})

	const [total, setTotal] = useState({
		your: 0,
		max: 0,
		percentile: 100,
	})

	const [focus, setFocus] = useState([])

	useEffect(() => {
		const getDetails = async () => {
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
					},
				}
				let body = {
					test_id: 1,
					section_id: 1,
					test_db_id: testDetails.mainDBId,
					section_db_ids: testDetails.sectionDBId,
					user_email: user.email,
				}

				// let body = {
				// 	test_id: 1,
				// 	section_id: 1,
				// 	test_db_id: 12,
				// 	section_db_ids: [45],
				// 	user_email: 'astitva.s@collegepass.org',
				// }

				const res = await axios.post(getFinalResultsAPI, body, config)
				// console.log(res.data)
				// console.log(res.data.sectionWiseScore[0])
				// console.log(res.data.Percentile.englishScore[0].OBTAINED_SCORE)

				res.data.sectionWiseScore.map((val) => {
					// looping to assign values
					console.log(val)
					if (val.SECTION_NAME === 'Reading') {
						setReading({
							your: val.OBTAINED_SCORE ? val.OBTAINED_SCORE : 0,
							max: val.SECTION_SCORE ? val.SECTION_SCORE : 0,
							percentile: res.data.Percentile.englishPercentile.PERCENTILE
								? res.data.Percentile.englishPercentile.PERCENTILE
								: 0,
						})
					}
					if (val.SECTION_NAME === 'Writing') {
						setWriting({
							your: val.OBTAINED_SCORE ? val.OBTAINED_SCORE : 0,
							max: val.SECTION_SCORE ? val.SECTION_SCORE : 0,
							percentile: res.data.Percentile.englishPercentile.PERCENTILE
								? res.data.Percentile.englishPercentile.PERCENTILE
								: 0,
						})
					}

					if (val.SECTION_NAME === 'Math') {
						setMath({
							your: val.OBTAINED_SCORE ? val.OBTAINED_SCORE : 0,
							max: val.SECTION_SCORE ? val.SECTION_SCORE : 0,
							percentile: res.data.Percentile.mathPercentile.PERCENTILE
								? res.data.Percentile.mathPercentile.PERCENTILE
								: 0,
						})
					}
				})

				setTotal({
					your: math.your + writing.your + reading.your,
					max: math.max + writing.max + reading.max,
					percentile: res.data.Percentile.totalPercentile.PERCENTILE
						? res.data.Percentile.mathPercentile.PERCENTILE
						: 0,
				})

				let focusEnglish = focus.filter((val) => {
					return val.sectionName === 'English'
				})

				let focusMath = focus.filter((val) => {
					return val.sectionName === 'Math'
				})

				setEngRowSpan(focusEnglish.length)
				setMathRowSpan(focusMath.length)

				setFocus(res.data.areaOfFocus)
			} catch (err) {
				console.log(err)
			}
		}
		getDetails()
	}, [])

	let focusComponentEnglish = null
	let focusComponentMath = null

	if (focus && focus.length > 0) {
		console.log(focus)
		let focusEnglish = focus.filter((val) => {
			return val.sectionName === 'English'
		})

		let focusMath = focus.filter((val) => {
			return val.sectionName === 'Math'
		})

		focusComponentEnglish = focusEnglish.map((val) => {
			return (
				<tr key={val.areaOFFocusName}>
					<td>{val.areaOFFocusName}</td>
					<td>{val.slabStatus}</td>
				</tr>
			)
		})
		focusComponentMath = focusMath.map((val) => {
			return (
				<tr key={val.areaOFFocusName}>
					<td>{val.areaOFFocusName}</td>
					<td>{val.slabStatus}</td>
				</tr>
			)
		})
	}

	const onClickFreeReportHandler = async () => {
		// call API

		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			}
			let body = {
				test_db_id: testDetails.mainDBId,
				user_email: user.email,
			}
			const res = await axios.post(requestFullReport, body, config)
		} catch (err) {
			console.log(err)
		}
		history.push(`/thank-you/${match.params.CPtestName}`)
	}

	return (
		<div className='wrap-result'>
			<HeaderSecond />
			<div className='wrap'>
				<Container fluid>
					<Container className='mt-5 mb-5'>
						<Row>
							<Col className='text-center'>
								<Row>
									<Col>
										<h1 className='pb-5 pt-2 mb-0 bg-black color-gold font-bolder'>
											Your SAT Diagnostic Test is successfully submitted.
										</h1>
									</Col>
								</Row>
								<div className='band pt-5'>
									<Row>
										<Col lg={2} md={2} sm={12} xs={12}></Col>
										<Col lg={8} md={8} sm={12} xs={12} className='mb-5'>
											<Table bordered variant='dark'>
												<thead>
													<tr>
														<th>Section</th>
														<th>Your Score</th>
														<th>Maximum Score</th>
														<th>Percentile</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Reading</td>
														<td>{reading.your}</td>
														<td>{reading.max}</td>
														<td rowSpan={2} className='my-auto'>
															{reading.percentile}
														</td>
													</tr>
													<tr>
														<td>Writing</td>
														<td>{writing.your}</td>
														<td>{writing.max}</td>
													</tr>
													<tr>
														<td>Maths</td>
														<td>{math.your}</td>
														<td>{math.max}</td>
														<td>{math.percentile}</td>
													</tr>
													<tr>
														<th>Total</th>
														<td>{math.your + writing.your + reading.your}</td>
														<td>{math.max + writing.max + reading.max}</td>
														<td>{total.percentile}</td>
													</tr>
												</tbody>
											</Table>
											<Row>
												<Col className='mb-3 mt-5'>
													<h1 className='border-custom color-gold'>
														Areas of focus
													</h1>
												</Col>
											</Row>

											<Table bordered variant='dark'>
												<tbody>
													<tr>
														<td rowSpan={engRowSpan} className='rowstyle eng'>
															English Section
														</td>
													</tr>
													{focusComponentEnglish}
												</tbody>
											</Table>

											<Table bordered variant='dark'>
												<tbody>
													<tr>
														<td rowSpan={mathRowSpan} className='rowstyle mth'>
															Math Section
														</td>
													</tr>
													{focusComponentMath}
												</tbody>
											</Table>

											<Col>
												<Row>
													<Col className='text-left'>
														<p className='ptag pt-4 pb-4 color-white'>
															A detailed report can be requested for free which
															will include:
														</p>
													</Col>
												</Row>
												<Row>
													<Col className='text-left'>
														<p className='ptag color-white'>
															&#128073; In-depth Analysis on Reading Section
															topics & passage types
														</p>
													</Col>
												</Row>
												<Row>
													<Col className='text-left'>
														<p className='ptag color-white'>
															&#128073; In-depth Analysis on Rhetorical skills &
															Grammar skills required on Writing Section
														</p>
													</Col>
												</Row>
												<Row>
													<Col className='text-left'>
														<p className='ptag color-white'>
															&#128073; In-depth Analysis on Maths Section for
															multiple choice as well as grid-in questions
														</p>
													</Col>
												</Row>
											</Col>
										</Col>
										<Col lg={2} md={2} sm={12} xs={12}></Col>
									</Row>
									<Row>
										<Col className='mb-5'>
											<Link
												className='button-cp'
												onClick={onClickFreeReportHandler}
											>
												Click to receive your &apos;free&apos; detailed report
											</Link>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				</Container>
			</div>
			<FooterSecond />
		</div>
	)
}

export default Result
