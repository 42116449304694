/* eslint-disable react/prop-types */
import React , {useEffect, useState} from 'react';
import './options.css';
import { Container, Row } from 'react-bootstrap';
import {TestDetails} from '../../config/testDetails';
import ObjectiveOptions from './objectiveOptions';
//import ConfirmModal from '../../components/modal/ConfirmModal';

// eslint-disable-next-line no-unused-vars
const Options = ({optionFor, testName, timerout, spentSec}) => {
    
    

    const [radioOption, setRadioOption] = useState("");

   // const [newspentSec, setSpentSec] = useState(spentSec);


    useEffect(() => {
        
        const getNextSection = async (optionFor,testName, spentSec)=>{

            var testKey = Object.keys(TestDetails).find(key => key === testName );
                
            let testData = TestDetails[testKey];

            
            
            if(testData.Sections.indexOf(optionFor)>= 0){

                let nextSectionIs = (testData.Sections[testData.Sections.indexOf(optionFor)+1])? `/instructions/${testName}/${testData.Sections[testData.Sections.indexOf(optionFor)+1]}`: `/result/${testName}`;

                setRadioOption(<ObjectiveOptions testdata={testData} sectionname={optionFor} nextSection={nextSectionIs} timerout={timerout} spentSec={spentSec}/>);

            }
        
        }

        getNextSection(optionFor,testName,spentSec);

       
        return () => {}
    }, [optionFor,testName, spentSec])

  return (
    <div className="wrap">
        <Container>
            <Row>
                {radioOption}
            </Row>
        </Container>
    </div>
  );
}

export default Options;

