export const TestDetails = {
    "sat" : {
        "testID":1,
        "SectionCount":4,
        "Sections" : [
            'cp-reading',
            'cp-writing',
            'cp-math-no-calci',
            'cp-math'
        ],
        "SectionsName" : {
            'cp-reading':'READING TEST',
            'cp-writing':'WRITING TEST',
            'cp-math-no-calci':'MATH TEST - CALCULATOR',
            'cp-math':'MATH TEST - NO CALCULATOR'
        },
        "SectionsPdf" : {
            'cp-reading' : 'https://collegepass.s3.ap-south-1.amazonaws.com/pdfresizer.com-pdf-split-reading.pdf',
            'cp-writing': 'https://collegepass.s3.ap-south-1.amazonaws.com/writing_section.pdf',
            'cp-math-no-calci': 'https://collegepass.s3.ap-south-1.amazonaws.com/math-no-calci.pdf',
            'cp-math': 'https://collegepass.s3.ap-south-1.amazonaws.com/math-calci.pdf'
        },
        "SectionQuestionCount" : {
            'cp-reading' : 52,
            'cp-writing': 44,
            'cp-math-no-calci': 20,
            'cp-math': 38
        },
        "SectionTimers" : {
            'cp-reading' : {
                'instruction':0,
                'section':65
            },
            'cp-writing': {
                'instruction':10,
                'section':35
            },
            'cp-math-no-calci': {
                'instruction':2,
                'section':25
            },
            'cp-math': {
                'instruction':5,
                'section':55
            },
        },
    }
}