/* eslint-disable react/prop-types */
import React,{useState, useEffect} from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Test from '../../components/test/Test';

const TestSection = ({match}) => {

  const [minutes, setMinutes] = useState(0)

  const [seconds, setSeconds] = useState(0)
  
  const [timerout, setTimerOut] = useState(false)

  const [spentSec, setSpentSec] = useState(0)


  let myInterval = setInterval(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1)
    }
    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(myInterval)
        setTimerOut(true);
      } else {
        setMinutes(minutes - 1)
        setSeconds(59)
      }
    }
  }, 1000)

  // console.log({minutes});

  // console.log({seconds});

  useEffect(() => {
    setSpentSec((minutes*60)+seconds);
    return () => {
      setSpentSec(0);
    }
  }, [minutes,seconds])

  
  //console.log({spentSec});


  return (
        <div className="wrap">
            <Header 
              timerSection={match.params.CPsectionName} 
              testName= {match.params.CPtestName} 
              headertype="section"
              setMinutes={setMinutes}
              minutes={minutes}
              seconds={seconds}
              myInterval={myInterval}
            />
              <Test pageOptionCp={match.params.CPsectionName} testName= {match.params.CPtestName} timerout={timerout} spentSec={spentSec}/>
            <Footer />
        </div>
  );
}

export default TestSection;
