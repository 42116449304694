/* eslint-disable react/prop-types */
import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {
	useSelector,
	// useDispatch
} from 'react-redux'
// import {setAlert} from '../actions/alertActions'

const PrivateRoute = ({component: Component, ...rest}) => {
	const auth = useSelector((state) => state.auth)
	const {isAuthenticated, loading, isTestTaken} = auth
	// const dispatch = useDispatch()

	if (!isAuthenticated || !loading) {
		// dispatch(setAlert('Please Login to continue', 'danger'))
	}
	if (isTestTaken) {
		// dispatch(
		// 	setAlert(
		// 		'This Account has already been used to take the test, please contact support',
		// 		'danger'
		// 	)
		// )
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				loading ? (
					<h2> Loading... </h2>
				) : isAuthenticated ? (
					!isTestTaken ? (
						<Component {...props} />
					) : (
						<Redirect to='/test/done' />
					)
				) : (
					<Redirect to='/login' />
				)
			}
		/>
	)
}

export default PrivateRoute
