import { Container, Row, Col } from 'react-bootstrap';
import './thankYou.css';
import HeaderSecond from '../../components/header/HeaderSecond';
import FooterSecond from '../../components/footer/FooterSecond';

const ThankYou = () => {


  return (
        <div className="wrap-thank">
            <HeaderSecond />
            <Container fluid>
                <Row>
                    <Col className="ticker">
                        <marquee>
                            <p>
                                Tanush scores 1580/1600 (Sep 2020)

                                <span style={{color: '#FDFB96',marginLeft:40}}>{"         "}Agnibha scores 1530/1600 (Sep 2020) </span>

                                <span style={{marginLeft:40}}>{"         "}  Mugilan scores 1520/1600 (Sep 2020)</span>

                                <span style={{color: '#FDFB96',marginLeft:40}}>{"         "}  Nachiket scores 1500/1600 (Sep 2020)</span>
                                
                            </p>
                        </marquee>
                    </Col>
                </Row>
            </Container>
            <div className="">
                <Container fluid>
                    <Container className="mt-5 mb-5">
                        <Row className="pt-5 pb-5">
                            <Col lg={2} md={2} sm={12} xs={12}></Col>
                            <Col lg={8} md={8} sm={12} xs={12} className="text-center">
                                <Row className="mt-5 mb-5">
                                    <div style={{width: '100%'}} className="band pt-5 pb-5">
                                        <Row>
                                            <Col>
                                                <h2  className="pb-3 pt-5 mb-0 color-gold font-bolder">
                                                You will receive your Detailed
                                                </h2>
                                                <h2  className="pb-5 mb-0 color-gold font-bolder">
                                                SAT Diagnostic Report within 24 hours!
                                                </h2>
                                            </Col>
                                        </Row>
                                       
                                        <Row className="mb-5">
                                            <Col className="pt-5">
                                                <a href="https://www.calendly.com/collegepass" className="button-cp">Book A Free College Admissions Session</a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12}></Col>
                        </Row>
                    </Container>
                </Container>
            </div>    
            <FooterSecond />
        </div>    
  );
}

export default ThankYou;
