/* eslint-disable no-unused-vars */
import {useState,useEffect} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import './options.css';

const SubjectiveOptions = ({ques, onOptionChange, finalOnchange}) => {

    let subjectiveOptionsList = '';

    const [state1, setstate1] = useState('')
    
    const [state2, setstate2] = useState('')

    const [state3, setstate3] = useState('')

    const [state4, setstate4] = useState('')

    useEffect(() => {
        
       
        const calltempFunc = async ()=>{
            await finalOnchange(state1,state2,state3,state4,ques.QUESTION_NO);
        }
        
        calltempFunc();
    }, [state4,state3,state2,state1])


    subjectiveOptionsList = <div key={ques.QUESTION_NO}>
    <Row className="textbxomr">
        <Col><h5 style={{marginLeft: '45%', marginTop: '15%'}}>{ques.QUESTION_NO}</h5></Col>
        <Col className="pl-0" data-tip data-for="textboxTip">
            <Form.Control type="text" name={`text1_${ques.QUESTION_NO}`}
        id={`text1_${ques.QUESTION_NO}`} value={state1} readOnly={true}/>
        </Col>
        <Col className="pl-0" data-tip data-for="textboxTip">
            <Form.Control type="text" name={`text2_${ques.QUESTION_NO}`}
        id={`text2_${ques.QUESTION_NO}`} value={state2} readOnly={true}/>
        </Col>
        <Col className="pl-0" data-tip data-for="textboxTip">
            <Form.Control type="text" name={`text3_${ques.QUESTION_NO}`}
        id={`text3_${ques.QUESTION_NO}`} value={state3} readOnly={true}/>
        </Col>
        <Col className="pl-0" data-tip data-for="textboxTip">
            <Form.Control type="text" name={`text4_${ques.QUESTION_NO}`}
        id={`text4_${ques.QUESTION_NO}`} value={state4} readOnly={true}/>
        </Col>
        <ReactTooltip id="textboxTip" place="left" effect="solid">
            Please select your answer blocks below
        </ReactTooltip>
    </Row>

    <Row className="omrdot dcss notAvail">
        <Col></Col>
        <Col data-tip data-for="registerTip">
            <Form.Check
                type="radio"
                label="NA"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios49_${ques.QUESTION_NO}`}
                value={""}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
                 />
        </Col>
        <Col data-tip data-for="registerTip">
            <Form.Check
                type="radio"
                label="NA"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios50_${ques.QUESTION_NO}`}
                value={""}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
                data-tip data-for="registerTip"
            />
        </Col>
        <Col data-tip data-for="registerTip">
            <Form.Check
                type="radio"
                label="NA"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios51_${ques.QUESTION_NO}`}
                value={""}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
                data-tip data-for="registerTip"
            />
        </Col>
        <Col data-tip data-for="registerTip">
            <Form.Check
                type="radio"
                label="NA"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios52_${ques.QUESTION_NO}`}
                value={""}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
                data-tip data-for="registerTip"
            />
        </Col>
        <ReactTooltip id="registerTip" place="left" effect="solid">
            Select to erase
        </ReactTooltip>
    </Row>
    <Row className="omrdot dcss twodts">
        <Col><h5></h5></Col>
        {/*<Col>
            <Form.Check
                type="radio"
                label="/"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios5_${ques.QUESTION_NO}`}
                value= "/"
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>*/}
        <Col>
            <Form.Check
                type="radio"
                label="/"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios6_${ques.QUESTION_NO}`}
                value= "/"
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
                style={{marginleft: '-10px'}}
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="/"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios7_${ques.QUESTION_NO}`}
                value= "/"
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        {/*<Col>
            <Form.Check
                type="radio"
                label="/"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios8_${ques.QUESTION_NO}`}
                value= "/"
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>*/}
    </Row>
    <Row className="omrdot dcss">
        <Col></Col>
        <Col>
            <Form.Check
                type="radio"
                label="."
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios1_${ques.QUESTION_NO}`}
                value= "."
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="."
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios2_${ques.QUESTION_NO}`}
                value= "."
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="."
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios3_${ques.QUESTION_NO}`}
                value= "."
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="."
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios4_${ques.QUESTION_NO}`}
                value= "."
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="0"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios9_${ques.QUESTION_NO}`}
                value= {0}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="0"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios10_${ques.QUESTION_NO}`}
                value= {0}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="0"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios11_${ques.QUESTION_NO}`}
                value= {0}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="0"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios12_${ques.QUESTION_NO}`}
                value= {0}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="1"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios13_${ques.QUESTION_NO}`}
                value= {1}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="1"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios14_${ques.QUESTION_NO}`}
                value= {1}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="1"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios15_${ques.QUESTION_NO}`}
                value= {1}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="1"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios16_${ques.QUESTION_NO}`}
                value= {1}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="2"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios17_${ques.QUESTION_NO}`}
                value= {2}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="2"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios18_${ques.QUESTION_NO}`}
                value= {2}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="2"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios19_${ques.QUESTION_NO}`}
                value= {2}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="2"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios20_${ques.QUESTION_NO}`}
                value= {2}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="3"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios21_${ques.QUESTION_NO}`}
                value= {3}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="3"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios22_${ques.QUESTION_NO}`}
                value= {3}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="3"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios23_${ques.QUESTION_NO}`}
                value= {3}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="3"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios24_${ques.QUESTION_NO}`}
                value= {3}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="4"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios25_${ques.QUESTION_NO}`}
                value= {4}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="4"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios26_${ques.QUESTION_NO}`}
                value= {4}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="4"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios27_${ques.QUESTION_NO}`}
                value= {4}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="4"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios28_${ques.QUESTION_NO}`}
                value= {4}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="5"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios29_${ques.QUESTION_NO}`}
                value= {5}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="5"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios30_${ques.QUESTION_NO}`}
                value= {5}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="5"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios31_${ques.QUESTION_NO}`}
                value= {5}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="5"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios32_${ques.QUESTION_NO}`}
                value= {5}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="6"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios33_${ques.QUESTION_NO}`}
                value= {6}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="6"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios34_${ques.QUESTION_NO}`}
                value= {6}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="6"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios35_${ques.QUESTION_NO}`}
                value= {6}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="6"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios36_${ques.QUESTION_NO}`}
                value= {6}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="7"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios37_${ques.QUESTION_NO}`}
                value= {7}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="7"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios38_${ques.QUESTION_NO}`}
                value= {7}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="7"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios39_${ques.QUESTION_NO}`}
                value= {7}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="7"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios40_${ques.QUESTION_NO}`}
                value= {7}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="8"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios41_${ques.QUESTION_NO}`}
                value= {8}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="8"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios42_${ques.QUESTION_NO}`}
                value= {8}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="8"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios43_${ques.QUESTION_NO}`}
                value= {8}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="8"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios44_${ques.QUESTION_NO}`}
                value= {8}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
    <Row className="omrdot">
        <Col><h5></h5></Col>
        <Col>
            <Form.Check
                type="radio"
                label="9"
                name={`formRadios1_${ques.QUESTION_NO}`}
                id={`Radios45_${ques.QUESTION_NO}`}
                value= {9}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",1);
                        setstate1(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="9"
                name={`formRadios2_${ques.QUESTION_NO}`}
                id={`Radios46_${ques.QUESTION_NO}`}
                value= {9}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",2);
                        setstate2(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="9"
                name={`formRadios3_${ques.QUESTION_NO}`}
                id={`Radios47_${ques.QUESTION_NO}`}
                value= {9}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",3);
                        setstate3(e.target.value);
                    }
                }
            />
        </Col>
        <Col>
            <Form.Check
                type="radio"
                label="9"
                name={`formRadios4_${ques.QUESTION_NO}`}
                id={`Radios48_${ques.QUESTION_NO}`}
                value= {9}
                onChange = {
                    (e) => {
                        onOptionChange(e,ques.QUESTION_NO,"SUBJECTIVE",4);
                        setstate4(e.target.value);
                    }
                }
            />
        </Col>
    </Row>
</div>

return (
    subjectiveOptionsList
)

}
export default SubjectiveOptions;