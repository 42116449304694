import axios from 'axios'

import {
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_FAILURE,
	LOGOUT,
	REGISTER_FAILURE,
} from '../constants/authConstants'

import {reactLocalStorage} from 'reactjs-localstorage'
import {token, email, isTestTaken, testDetails} from '../config/localStorage'

import {setAlert} from './alertActions'
import {
	signInAPI,
	tokenRefreshAPI,
	checkTestGivenStatusAPI,
	registerAPI,
} from '../config/api'

//Load User
export const loadAuthDetails = (tokenIncoming) => async (dispatch) => {
	// console.log(tokenIncoming)

	try {
		const config = {
			headers: {
				Authorization: `Bearer ${tokenIncoming}`,
				'Content-Type': 'application/json',
			},
		}
		const {data} = await axios.get(tokenRefreshAPI, config)
		console.log(data)

		const testStatus = await axios.get(
			`${checkTestGivenStatusAPI}/${data.email}/1}`
		)

		// console.log(testStatus)

		let payload = {
			token: tokenIncoming,
			isAuthenticated: true,
			loading: false,
			email: data.email,
			isTestTaken: testStatus.data.testStatus,
			// testDetails: {},
		}

		reactLocalStorage.set(token, tokenIncoming)
		reactLocalStorage.set(email, data.email)
		reactLocalStorage.set(isTestTaken, testStatus.data.testStatus)
		// reactLocalStorage.set(testDetails, {})

		dispatch({
			type: USER_LOADED,
			payload,
		})

		window.location.href = '/sat/welcome'
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		})
		window.location.href = '/error/404'
	}
}

//Reload User Details
export const reloadAuthDetails = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				Authorization: `Bearer ${reactLocalStorage.get(token, null, true)}`,
				'Content-Type': 'application/json',
			},
		}
		const {data} = await axios.get(tokenRefreshAPI, config)
		// console.log(data)

		const testStatus = await axios.get(
			`${checkTestGivenStatusAPI}/${data.email}/1`
		)

		// console.log(testStatus)

		let payload = {
			token: reactLocalStorage.get(token, null, true),
			isAuthenticated: true,
			loading: false,
			email: data.email,
			isTestTaken: testStatus.data.testStatus,
			// testDetails: {},
		}

		reactLocalStorage.set(email, data.email)
		reactLocalStorage.set(isTestTaken, testStatus.data.testStatus)
		// reactLocalStorage.set(testDetails, {})

		dispatch({
			type: USER_LOADED,
			payload,
		})

		// window.location.href = '/sat/welcome'
	} catch (err) {
		dispatch({
			type: AUTH_ERROR,
		})
	}
}

//LOGIN USER
export const login = (email, password) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}
		let body = {
			EMAIL: email,
			PASSWORD: password,
		}
		const res = await axios.post(signInAPI, body, config)
		// console.log(res.data);

		dispatch(loadAuthDetails(res.data.token))
	} catch (err) {
		console.log(err)
		dispatch(
			setAlert(
				'Unable to login, if issue persists please contact support',
				'danger'
			)
		)
		dispatch({
			type: LOGIN_FAILURE,
		})
	}
}

//REGISTER USER
export const register = (email, password) => async (dispatch) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	try {
		const res = await axios.post(
			registerAPI,
			{
				EMAIL: email,
				PASSWORD: password,
			},
			config
		)

		console.log(res.data.token)

		dispatch(loadAuthDetails(res.data.token))
	} catch (err) {
		console.log(err)
		dispatch(
			setAlert(
				'Unable to login, if issue persists please contact support',
				'danger'
			)
		)
		dispatch({
			type: REGISTER_FAILURE,
		})
	}
}

//LOGOUT
export const logout = () => (dispatch) => {
	dispatch({
		type: LOGOUT,
	})

	reactLocalStorage.remove(token, email, isTestTaken, testDetails)

	window.location.href = '/'
}
