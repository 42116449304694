import { Container, Row, Col, Table } from 'react-bootstrap';
import Header from '../header/Header';
import Footer from '../footer/Footer';


const Welcome = () => {

  return (
        <div className="wrap-thank">
            <Header />
              <div className="pt-5">
                  <Container fluid>
                      <Container >
                          <Row className="pt-5 pb-5 mt-5 mb-5">
                            <Col className="text-center ins">
                              <Row>
                                <Col>
                                  <h1 className="pb-5 mb-0 pt-2 bg-black color-gold font-bolder">SAT Diagnostic Test</h1>
                                </Col>
                              </Row>
                              <div className="band pt-5">
                                <Row>
                                  <Col className="mb-1">
                                    <h2 style={{
                                      fontSize: '35px', fontWeight: '600'
                                    }}>Instructions to take test</h2>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mb-5">
                                    <h5 style={{
                                      fontWeight: '600', fontSize: '20px'
                                    }}>The test is divided into 4 sections</h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                                  <Col lg={8} md={8} sm={12} xs={12} className="mb-5 reading-ins-table">
                                    <Table bordered variant="dark">
                                      <thead>
                                        <tr>
                                          <th>Section</th>
                                          <th>No. of questions</th>
                                          <th>Time Available</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Reading</td>
                                          <td>52</td>
                                          <td>65 mins</td>
                                        </tr>
                                        <tr>
                                          <td>Writing</td>
                                          <td>44</td>
                                          <td>35 mins</td>
                                        </tr>
                                        <tr>
                                          <td>Maths - No Calculator</td>
                                          <td>20</td>
                                          <td>25 mins</td>
                                        </tr>
                                        <tr>
                                          <td>Maths - Calculator</td>
                                          <td>38</td>
                                          <td>55 mins</td>
                                        </tr>
                                        <tr>
                                          <th>Total</th>
                                          <th>154</th>
                                          <th>180 mins</th>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </Col>
                                  <Col lg={2} md={2} sm={12} xs={12}></Col>
                                </Row>
                              </div>
                              <Row>
                                <Col className="mt-5">
                                  <a href="/instructions/sat/cp-reading" className="button-cp">Get Started</a>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                      </Container>
                  </Container>
              </div>    
            <Footer />
        </div>    
  );
}
export default Welcome;