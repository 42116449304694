/* eslint-disable react/prop-types */
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Instruction from '../../components/instruction/Instruction'
import React,{useState} from 'react';

const TestInstructions = ({match}) => {

	const [minutes, setMinutes] = useState(0)

	const [seconds, setSeconds] = useState(0)
	
	const [timerout, setTimerOut] = useState(false)

	let myInterval = setInterval(() => {
		if (seconds > 0) {
		setSeconds(seconds - 1)
		}
		if (seconds === 0) {
		if (minutes === 0) {
			clearInterval(myInterval)
			setTimerOut(true);
		} else {
			setMinutes(minutes - 1)
			setSeconds(59)
		}
		}
  }, 1000);

	return (
		<div className='wrap'>
			<Header
				timerSection={match.params.sectionName}
				testName={match.params.testName}
				headertype='instruction'
				setMinutes={setMinutes}
				minutes={minutes}
				seconds={seconds}
				myInterval={myInterval}
			/>
			<Instruction
				instructionPage={match.params.sectionName}
				testName={match.params.testName}
				timerout={timerout}
			/>
			<Footer />
		</div>
	)
}

export default TestInstructions
