/* eslint-disable react/prop-types */
import {Container, Row, Col, Table} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import './instruction.css'
import {initialiseTest} from '../../actions/testActions'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect} from 'react'

const Instruction = ({instructionPage, testName, timerout}) => {
	let history = useHistory()
	const dispatch = useDispatch()
	const auth = useSelector((state) => state.auth)

	let instructionText = ''

	useEffect(() => {
		console.log({timerout})

		if (timerout === true) {
			history.push(`/cp-test/${testName}/${instructionPage}`)
		}

		return () => {}
	}, [timerout])

	const startDiagnosticTest = () => {
		dispatch(initialiseTest(auth.email, 1))
		history.push(`/cp-test/${testName}/${instructionPage}`)
	}
	const startWritingSection = () => {
		// dispatch(initialiseTest(auth.email, 1))
		history.push(`/cp-test/${testName}/${instructionPage}`)
	}
	const startMathNoCalcSection = () => {
		// dispatch(initialiseTest(auth.email, 1))
		history.push(`/cp-test/${testName}/${instructionPage}`)
	}
	const startMathCalcSection = () => {
		// dispatch(initialiseTest(auth.email, 1))
		history.push(`/cp-test/${testName}/${instructionPage}`)
	}

	if (instructionPage === 'cp-reading') {
		instructionText = (
			<Col>
				<Row>
					<Col lg={2} md={2} sm={12} xs={12}></Col>
					<Col lg={8} md={8} sm={12} xs={12} className='text-left color-white'>
						<Row>
							<Col>
								<p
									style={{
										fontWeight: '600',
										fontSize: '24px',
										opacity: '0.8',
									}}
								>
									Test Directions:
								</p>
								<p className=''>
									Important: Read this information before selecting the{' '}
									<span className='color-red'>Start Your Diagnostic Test</span>{' '}
									button.
								</p>
								<p className=''>
									You are about to take the SAT, which is composed of
									multiple-choice tests in Reading, Writing and Language, Math -
									No Calculator, and Math - Calculator.
								</p>
								<p className=''>
									For each multiple-choice question, select the circle in the
									grid on the right side of the screen that is lettered the same
									as your choice.
								</p>
								<p className=''>
									If you would like to change your response, select the circle
									that corresponds to it.
								</p>
								<p className=''>
									Remember that you receive points for correct answers, but you
									don’t lose points for incorrect answers. That means you should
									try to answer every question, even if you’re not sure of the
									correct answer.
								</p>
								<p className=''>
									The computer will keep the official time for your examination.
									There will be a countdown timer that will tell you how much
									time is remaining for the section you are working on.
								</p>
								<p className=''>
									If you finish a section early, you select Submit Answers. Be
									careful not to do so unless you are completely done. You will
									not be able to look back at the questions once a test is
									complete.
								</p>
								<p
									className='mb-5'
									
								>
									WARNING: YOUR TIME WILL BEGIN ONCE YOU SELECT THE{' '}
									<span className='color-red'>Start Your Diagnostic Test</span>{' '}
									button
								</p>
								<Link
									onClick={startDiagnosticTest}
									className='button-cp text-center border-none'
								>
									Start Your Diagnostic Test
								</Link>
							</Col>
						</Row>
					</Col>
					<Col lg={2} md={2} sm={12} xs={12}></Col>
				</Row>
			</Col>
		)
	} else if (instructionPage === 'cp-writing') {
		instructionText = (
			<Col className='text-center ins'>
				<Row>
					<Col className='mb-5'>
						<h2
							className='mb-0 color-gold font-bolder'
							
						>
							Your Reading Section answers have been submitted.
						</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1 className='pb-5 pt-2 mb-0 bg-black color-white'>
							Next Section - Writing Section
						</h1>
					</Col>
				</Row>
				<div className='band pt-5'>
					<Row>
						<Col className='mb-5'>
							<h5
								style={{
									fontSize: '20px',
									fontWeight: '600',
								}}
							>
								You can take a 10 minutes break before you start the Writing
								Section
							</h5>
						</Col>
					</Row>
					<Row>
						<Col lg={2} md={2} sm={12} xs={12}></Col>
						<Col
							lg={8}
							md={8}
							sm={12}
							xs={12}
							className='mb-6 writing-ins-table'
						>
							<Table bordered variant='dark'>
								<thead>
									<tr>
										<th>No. of questions</th>
										<th>Time Available</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>44</td>
										<td>35 mins</td>
									</tr>
								</tbody>
							</Table>
							<Col className='mt-5 mb-5'>
								<p className='ptag color-white'>
									All questions on this section are multiple choice based
								</p>
							</Col>
						</Col>
						<Col lg={2} md={2} sm={12} xs={12}></Col>
					</Row>
				</div>
				<Row>
					<Col className='mt-5'>
						<Link onClick={startWritingSection} className='button-cp'>
							Start Writing Section
						</Link>
					</Col>
				</Row>
			</Col>
		)
	} else if (instructionPage === 'cp-math-no-calci') {
		instructionText = (
			<Col className='text-center mb-0 ins'>
				<Row>
					<Col className='mb-5'>
						<h2
							className='mb-0 color-gold font-bolder'
							
						>
							Your Writing Section answers have been submitted.
						</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1 className='pb-5 pt-2 mb-0 bg-black color-white'>
							Next Section - Maths (No Calculator) Section
						</h1>
					</Col>
				</Row>
				<div className='band pt-5'>
					<Row>
						<Col lg={2} md={2} sm={12} xs={12}></Col>
						<Col
							lg={8}
							md={8}
							sm={12}
							xs={12}
							className='mb-5 writing-ins-table'
						>
							<Table bordered variant='dark'>
								<thead>
									<tr>
										<th>No. of questions</th>
										<th>Time Available</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>20</td>
										<td>25 mins</td>
									</tr>
								</tbody>
							</Table>
							<Col>
								<Row>
									<Col className='text-left'>
										<p className='ptag color-white'>
											Q 1 - Q 15 are multiple choice based
										</p>
									</Col>
								</Row>
								<Row>
									<Col className='text-left'>
										<p className='ptag color-white'>
											Q 16 - Q 20 are Free Response questions
										</p>
									</Col>
								</Row>
								<Row>
									<Col className='text-left mt-3'>
										<p className='ptag color-white'>
											You are <span>not allowed to use calculator</span> for
											this section
										</p>
									</Col>
								</Row>
							</Col>
						</Col>
						<Col lg={2} md={2} sm={12} xs={12}></Col>
					</Row>
				</div>
				<Row>
					<Col className='mt-5'>
						<Link onClick={startMathNoCalcSection} className='button-cp'>
							Start Maths (No Calculator) Section
						</Link>
					</Col>
				</Row>
			</Col>
		)
	} else if (instructionPage === 'cp-math') {
		instructionText = (
			<Col className='text-center ins'>
				<Row>
					<Col className='mb-5'>
						<h2
							className='mb-0 color-gold font-bolder'
							
						>
							Your Maths (No Calculator) Section answers have been submitted.
						</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1 className='pb-5 pt-2 mb-0 bg-black color-white'>
							Next Section - Maths (Calculator) Section
						</h1>
					</Col>
				</Row>
				<div className='band pt-5'>
					<Row>
						<Col className='mb-5'>
							<h2
								className='mb-0'
								style={{
									fontSize: '20px',
									fontWeight: '600',
								}}
							>
								You can take a 5 minutes break before you start the Maths
								(Calculator) Section
							</h2>
						</Col>
					</Row>
					<Row>
						<Col lg={2} md={2} sm={12} xs={12}></Col>
						<Col
							lg={8}
							md={8}
							sm={12}
							xs={12}
							className='mb-5 writing-ins-table'
						>
							<Table bordered variant='dark'>
								<thead>
									<tr>
										<th>No. of questions</th>
										<th>Time Available</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>38</td>
										<td>55 mins</td>
									</tr>
								</tbody>
							</Table>
							<Col>
								<Row>
									<Col className='text-left'>
										<p className='ptag color-white'>
											Q 1 - Q 30 are multiple choice based
										</p>
									</Col>
								</Row>
								<Row>
									<Col className='text-left'>
										<p className='ptag color-white'>
											Q 31- Q 38 are Free Response questions
										</p>
									</Col>
								</Row>
								<Row>
									<Col className='text-left'>
										<p className='ptag color-white'>
											You are <span>allowed to use calculator</span> for this
											section
										</p>
									</Col>
								</Row>
							</Col>
						</Col>
						<Col lg={2} md={2} sm={12} xs={12}></Col>
					</Row>
				</div>
				<Row>
					<Col className='mt-5'>
						<Link onClick={startMathCalcSection} className='button-cp'>
							Start Maths (Calculator) Section
						</Link>
					</Col>
				</Row>
			</Col>
		)
	}

	return (
		<div className='instruction-wrap'>
			<Container fluid>
				<Row>
					<Container>
						<Row className='pt-5 pb-5 mt-5 mb-5'>
							<Col className='instruction'>
								<Row>{instructionText}</Row>
							</Col>
						</Row>
					</Container>
				</Row>
			</Container>
		</div>
	)
}

export default Instruction
