/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {Chart} from 'chart.js'
//import { Pie } from 'react-chartjs-2';
import HeaderSecond from '../../components/header/HeaderSecond'
import Footer from '../../components/footer/Footer'
import './result.css'
import 'chartjs-plugin-datalabels'
import axios from 'axios'
import {emailReportAPI} from '../../config/api'
import {useSelector} from 'react-redux'
import moment from 'moment'

const EmailTemplateInternal = ({match}) => {
	const mainDBTestID = match.params.testID
    const userEmail = match.params.userEmail
	const auth = useSelector((state) => state.auth)

	const [dbData, setDbData] = useState({})
	const [englishReading, setEnglishReading] = useState()
	const [englishWriting, setEnglishWriting] = useState()
	const [math, setMath] = useState()
	const [focus, setFocus] = useState([])
	const [englishReadAccuracy, setEnglishReadAccuracy] = useState([])
	const [englishWriteAccuracy, setEnglishWriteAccuracy] = useState([])
	const [mathAccuracy, setMathAccuracy] = useState([])
	const [mathTopicAccuracy, setMathTopicAccuracy] = useState([])




	useEffect(() => {
		const getDetails = async () => {
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
					},
				}
				const res = await axios.get(
					`${emailReportAPI}/${mainDBTestID}/${userEmail}`,
					config
				)
				// //console.log(res.data)
				setDbData(res.data)

				res.data.sectionWiseScore.map((val) => {
					// //console.log(val)
					if (val.SECTION_NAME === 'Reading') {
						setEnglishReading(val)
					}
					if (val.SECTION_NAME === 'Writing') {
						setEnglishWriting(val)
					}
					if (val.SECTION_NAME === 'Math') {
						setMath(val)
					}
					return null
				})

				setFocus(res.data.areaOfFocus);

				//setting up the reading accuracy chart

				let engReadingValue = res.data.englishReading.type;
				const readingValueArray = [];
				for(let i=0;i<=3;i++){
					const element = engReadingValue[i];

					if(readingValueArray[0] === undefined || readingValueArray[0] === 0){
						readingValueArray[0] = (element !== undefined && element.Literature_Passage!== undefined)?element.Literature_Passage:0;
					}
						
					if(readingValueArray[1] === undefined || readingValueArray[1] === 0){
						readingValueArray[1] = (element !== undefined && element.Social_Science_Passage!== undefined)?element.Social_Science_Passage:0;
					}
					if(readingValueArray[2] === undefined || readingValueArray[2] === 0){
						readingValueArray[2] = (element !== undefined && element.Science_Passage!== undefined)?element.Science_Passage:0;
					}
					if(readingValueArray[3] === undefined || readingValueArray[3] === 0){
						readingValueArray[3] = (element !== undefined && element.Paired_Passage_History!== undefined)?element.Paired_Passage_History:0;
					}
						
				}

				setEnglishReadAccuracy(readingValueArray);

				// setting the Writing chart of accuracy

				let engWritingValue = res.data.englishWriting.type;
				const writingValueArray = [];
				for(let i=0;i<=1;i++){
					const element = engWritingValue[i];

					if(writingValueArray[0] === undefined || writingValueArray[0] === 0){
						writingValueArray[0] = (element !== undefined && element.Grammar!== undefined)?element.Grammar:0;
					}
						
					if(writingValueArray[1] === undefined || writingValueArray[1] === 0){
						writingValueArray[1] = (element !== undefined && element.Rhetorical!== undefined)?element.Rhetorical:0;
					}
					
				}

				setEnglishWriteAccuracy(writingValueArray);

				// setting the Math chart of accuracy

				let mathValue = res.data.mathCalci.type;
				const mathArray = [];
				for(let i=0;i<=1;i++){
					const element = mathValue[i];

					if(mathArray[0] === undefined || mathArray[0] === 0){
						mathArray[0] = (element !== undefined && element.Multiple_Choice!== undefined)?element.Multiple_Choice:0;
					}
						
					if(mathArray[1] === undefined || mathArray[1] === 0){
						mathArray[1] = (element !== undefined && element.Grid_in!== undefined)?element.Grid_in:0;
					}
					
				}

				setMathAccuracy(mathArray);

				// setting the Math chart of accuracy of topics

				let mathTopicsValue = res.data.mathNocalci.type;
				const mathTopicsArray = [];
				for(let i=0;i<=3;i++){
					const element = mathTopicsValue[i];

					if(mathTopicsArray[0] === undefined || mathTopicsArray[0] === 0){
						mathTopicsArray[0] = (element !== undefined && element.Heart_of_Algebra!== undefined)?element.Heart_of_Algebra:0;
					}
						
					if(mathTopicsArray[1] === undefined || mathTopicsArray[1] === 0){
						mathTopicsArray[1] = (element !== undefined && element.Problem_Solving_And_Data_Analysis!== undefined)?element.Problem_Solving_And_Data_Analysis:0;
					}

					if(mathTopicsArray[2] === undefined || mathTopicsArray[2] === 0){
						mathTopicsArray[2] = (element !== undefined && element.Passport_to_Advanced_Maths!== undefined)?element.Passport_to_Advanced_Maths:0;
					}

					if(mathTopicsArray[3] === undefined || mathTopicsArray[3] === 0){
						mathTopicsArray[3] = (element !== undefined && element.Additional_Topics_in_Maths!== undefined)?element.Additional_Topics_in_Maths:0;
					}
					
				}

				setMathTopicAccuracy(mathTopicsArray);
			
			} catch (err) {
				console.log(err)
			}
		}
		getDetails()
	}, [])
	
	var data = {
		labels: [
			'Literature Passage',
			'Social Science Passage',
			'Science Passage',
			'Paired Passage (History)',
		],
		datasets: [
			{
				label: 'Reading Section Accuracy',
				backgroundColor: '#f1c232',
				borderColor: '#edba1e',
				borderWidth: 2,
				hoverBackgroundColor: '#f1c232',
				hoverBorderColor: '#edba1e',
				data: [englishReadAccuracy[0] || 0,englishReadAccuracy[1] || 0,englishReadAccuracy[2] || 0,englishReadAccuracy[3] || 0],
			},
		],
	}

	//console.log({englishReadAccuracy});

	var options = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				anchor: 'end',
				clamp: 'false',
				align: 'end',
				formatter: (value) => {
					let percentage = value.toFixed(0) + '%'
					return percentage
				},
			},
		},
		maintainAspectRatio: false,
		scales: {
			yAxes: [
				{
					ticks: {
						min: 0,
						max: 100,
						stepSize: 20,
					},
					stacked: true,
					gridLines: {
						display: false,
						color: 'rgba(255,99,132,0.2)',
					},
					axisY: {
						suffix: '%',
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	}

	Chart.defaults.global.legend.display = false

	Chart.Bar('chart', {
		options: options,
		data: data,
	})

	var data2 = {
		labels: ['Grammar', 'Rhetorical'],
		datasets: [
			{
				label: 'Writing Section Accuracy',
				backgroundColor: '#f1c232',
				borderColor: '#edba1e',
				borderWidth: 2,
				hoverBackgroundColor: '#f1c232',
				hoverBorderColor: '#edba1e',
				data: englishWriteAccuracy,
			},
		],
	}

	var options2 = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				anchor: 'end',
				clamp: 'false',
				align: 'end',
				formatter: (value) => {
					let percentage = value.toFixed(0) + '%'
					return percentage
				},
			},
		},
		maintainAspectRatio: false,
		scales: {
			yAxes: [
				{
					ticks: {
						min: 0,
						max: 100,
						stepSize: 20,
					},
					stacked: true,
					gridLines: {
						display: false,
						color: 'rgba(255,99,132,0.2)',
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	}

	Chart.Bar('chart2', {
		options: options2,
		data: data2,
	})

	var data3 = {
		labels: ['Multiple Choice', 'Grid-in'],
		datasets: [
			{
				label: 'Math Section Accuracy',
				backgroundColor: '#f1c232',
				borderColor: '#edba1e',
				borderWidth: 2,
				hoverBackgroundColor: '#f1c232',
				hoverBorderColor: '#edba1e',
				data: mathAccuracy,
			},
		],
	}

	var options3 = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				anchor: 'end',
				clamp: 'false',
				align: 'end',
				formatter: (value) => {
					let percentage = value.toFixed(0) + '%'
					return percentage
				},
			},
		},
		maintainAspectRatio: false,
		scales: {
			yAxes: [
				{
					ticks: {
						min: 0,
						max: 100,
						stepSize: 20,
					},
					stacked: true,
					gridLines: {
						display: false,
						color: 'rgba(255,99,132,0.2)',
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	}

	Chart.Bar('chart3', {
		options: options3,
		data: data3,
	})

	var data4 = {
		type: 'horizontalBar',
		labels: [
			'Heart of Algebra',
			'Problem Solving & Data Analysis',
			'Passport to Advanced Maths',
			'Additional Topics in Maths',
		],
		datasets: [
			{
				label: 'Accuracy on Math Topics',
				backgroundColor: '#f1c232',
				borderColor: '#edba1e',
				borderWidth: 2,
				hoverBackgroundColor: '#f1c232',
				hoverBorderColor: '#edba1e',
				data: mathTopicAccuracy,
			},
		],
	}

	var options4 = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				anchor: 'end',
				clamp: 'false',
				align: 'end',
				formatter: (value) => {
					let percentage = value.toFixed(0) + '%'
					return percentage
				},
			},
		},
		maintainAspectRatio: false,
		scales: {
			yAxes: [
				{
					ticks: {
						min: 0,
						max: 100,
						stepSize: 20,
					},
					stacked: true,
					gridLines: {
						display: false,
						color: 'rgba(255,99,132,0.2)',
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	}

	Chart.Bar('chart4', {
		options: options4,
		data: data4,
	})

	//console.log({dbData})
	// //console.log(englishReading)

	

	let totalScoreObtained = 0
	let totalSectionScore = 0

	if (
		englishReading !== undefined &&
		englishWriting !== undefined &&
		math !== undefined
	) {
		totalScoreObtained =
			englishReading.OBTAINED_SCORE +
			englishWriting.OBTAINED_SCORE +
			math.OBTAINED_SCORE
	}
	if (
		englishReading !== undefined &&
		englishWriting !== undefined &&
		math !== undefined
	) {
		totalSectionScore =
			englishReading.SECTION_SCORE +
			englishWriting.SECTION_SCORE +
			math.SECTION_SCORE
	}

	let focusComponentEnglish = null
	let focusComponentMath = null

	if (focus && focus.length > 0) {
		// //console.log(focus)
		let focusEnglish = focus.filter((val) => {
			return val.sectionName === 'English'
		})

		let focusMath = focus.filter((val) => {
			return val.sectionName === 'Math'
		})

		focusComponentEnglish = focusEnglish.map((val) => {
			return (
				<tr key={val.areaOFFocusName} style={{borderCollapse: 'collapse'}}>
					<td
						style={{
							padding: '10px',
							margin: '0',
							textAlign: 'center',
							border: '1px solid black',
							borderCollapse: 'collapse',
						}}
					>
						{val.areaOFFocusName}
					</td>
					<td
						style={{
							padding: '10px',
							margin: '0',
							textAlign: 'center',
							border: '1px solid black',
							borderCollapse: 'collapse',
						}}
					>
						{val.slabStatus}
					</td>
				</tr>
			)
		})
		focusComponentMath = focusMath.map((val) => {
			return (
				<tr key={val.areaOFFocusName} style={{borderCollapse: 'collapse'}}>
					<td
						style={{
							padding: '10px',
							margin: '0',
							textAlign: 'center',
							border: '1px solid black',
							borderCollapse: 'collapse',
						}}
					>
						{val.areaOFFocusName}
					</td>
					<td
						style={{
							padding: '10px',
							margin: '0',
							textAlign: 'center',
							border: '1px solid black',
							borderCollapse: 'collapse',
						}}
					>
						{val.slabStatus}
					</td>
				</tr>
			)
		})
	}

	return (
		<div className='wrap-thank bg-white'>
			<HeaderSecond />
			<Container>
				<Row className='color-black emailTemplateMain'>
					<Col></Col>
					<Col lg={10} className='bg-white'>
						<Row
							className='header-e pt-3 pb-3'
							style={{
								borderTop: '1px solid #ffffff',
							}}
						>
							<Col className='text-center'>
								<Row>
									<Col lg={12}>
										<img src='/holo.png' className='hloimg' />
									</Col>
									<Col lg={12}>
										<img
											src='https://collegepass.s3.ap-south-1.amazonaws.com/GoldNLogo.jpg'
											className='lgoimg'
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className='name-section'>
							<Col className='text-center color-black'>
								<p className='mb-0'>
									{dbData.userName ? dbData.userName : 'loading..'}
								</p>
							</Col>
							<Col className='text-center color-black'>
								<p className='mb-0'>
									{dbData.userGrade ? dbData.userGrade : 'loading..'}
								</p>
							</Col>

							<Col className='text-center color-black'>
								<p className='mb-0'>
									Test Taken On :{' '}
									{dbData.testDate
										? moment.utc(dbData.testDate).format('DD-MMM-YYYY')
										: 'loading..'}
								</p>
							</Col>
						</Row>
						<Row className='table-bg-gray pb-3'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h2 className='mheading color-black pt-3 text-center'>
									CollegePass SAT Diagnostic Test Report
								</h2>
								<table
									style={{
										borderCollapse: 'collapse',
										borderSpacing: '0px',
										width: '100%',
									}}
								>
									<tbody>
										<tr style={{borderCollapse: 'collapse'}}>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Section
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Your Score
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Maximum Score
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Percentile
											</th>
										</tr>
										<tr style={{borderCollapse: 'collapse'}}>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												Reading
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 300 */}
												{englishReading !== undefined
													? englishReading.OBTAINED_SCORE
													: 'loading..'}
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 400 */}
												{englishReading !== undefined
													? englishReading.SECTION_SCORE
													: 'loading..'}
											</td>
											<td
												rowSpan='2'
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{dbData.Percentile
													? dbData.Percentile.englishPercentile.PERCENTILE
													: 'loading..'}
											</td>
										</tr>
										<tr style={{borderCollapse: 'collapse'}}>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												Writing
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 270 */}
												{englishWriting !== undefined
													? englishWriting.OBTAINED_SCORE
													: 'loading..'}
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 400 */}
												{englishWriting !== undefined
													? englishWriting.SECTION_SCORE
													: 'loading..'}
											</td>
										</tr>
										<tr style={{borderCollapse: 'collapse'}}>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												Maths
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 580 */}
												{math !== undefined ? math.OBTAINED_SCORE : 'loading..'}
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 800 */}
												{math !== undefined ? math.SECTION_SCORE : 'loading..'}
											</td>
											<td
												style={{
													padding: '10px',
													margin: '0',
													textAlign: 'center',
													border: '1px solid black',
													borderCollapse: 'collapse',
												}}
											>
												{/* 69 */}
												{dbData.Percentile
													? dbData.Percentile.mathPercentile.PERCENTILE
													: 'loading..'}
											</td>
										</tr>
										<tr style={{borderCollapse: 'collapse'}}>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Total
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												{/* 1150 */}
												{totalScoreObtained}
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												{/* 1600 */}
												{totalSectionScore}
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												{/* 67 */}
												{dbData.Percentile
													? dbData.Percentile.totalPercentile.PERCENTILE
													: 'loading..'}
											</th>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className='pt-3'>
								<h2 className='mheading color-black text-center'>Diagnosis</h2>
								<table
									style={{
										borderCollapse: 'collapse',
										borderSpacing: '0px',
										width: '100%',
									}}
								>
									<tbody>
										<tr style={{borderCollapse: 'collapse'}}>
											<th
												colSpan='2'
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													textAlign: 'center',
													padding: '10px 0px 10px 0px',
												}}
											>
												English Section
											</th>
										</tr>

										<tr style={{borderCollapse: 'collapse'}}>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Area of Focus
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Your Performance
											</th>
										</tr>
										{focusComponentEnglish}
									</tbody>
								</table>
							</Col>
						</Row>
						<Row className='bg-white pt-3 pb-3 mt-5 border'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h5 className='text-center color-black secheadng'>
									Reading Section Accuracy
								</h5>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<canvas id='chart' width='100%' height='300px'></canvas>
							</Col>
						</Row>
						<Row className='bg-black text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-white'>
											Mistakes made on Reading Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{/* Q3, Q8, Q11, Q16, Q23, Q26, Q27, Q28, Q33, Q36, Q45,
													Q47, Q48, Q49,{' '} */}
													{dbData.englishReading !== undefined
														? dbData.englishReading.wrongQues.toString()
														: 'loading'}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row className='bg-gray text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-black'>
											Topics to focus on Reading Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{dbData.englishReading !== undefined
														? dbData.englishReading.topic.toString()
														: 'loading'}{' '}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row className='bg-white pt-3 pb-3 mt-5 border'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h5 className='text-center color-black secheadng'>
									Writing Section Accuracy
								</h5>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<canvas id='chart2' width='100%' height='300px'></canvas>
							</Col>
						</Row>
						<Row className='bg-black text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-white'>
											Mistakes made on Writing Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{dbData.englishWriting !== undefined
														? dbData.englishWriting.wrongQues.toString()
														: 'loading'}{' '}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row className='bg-gray text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-black'>
											Topics to focus on Writing Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{dbData.englishWriting !== undefined
														? dbData.englishWriting.topic.toString()
														: 'loading'}{' '}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row className='table-bg-gray pb-5 mt-5'>
							<Col lg={12} md={12} sm={12} xs={12} className='pt-5'>
								<table
									style={{
										borderCollapse: 'collapse',
										borderSpacing: '0px',
										width: '100%',
									}}
								>
									<tbody>
										<tr style={{borderCollapse: 'collapse'}}>
											<th
												colSpan='2'
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													textAlign: 'center',
													padding: '10px 0px 10px 0px',
												}}
											>
												Math Section
											</th>
										</tr>
										<tr style={{borderCollapse: 'collapse'}}>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Area of Focus
											</th>
											<th
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													padding: '10px',
													textAlign: 'center',
												}}
											>
												Your Performance
											</th>
										</tr>

										{focusComponentMath}
									</tbody>
								</table>
							</Col>
						</Row>
						<Row className='bg-white pt-3 pb-3 border'>
							<Col lg={12} md={12} sm={12} xs={12}>
								{/*<h5 className='text-center color-black secheadng'>
									Math - Proportion of Topics
								</h5>*/}
							</Col>
							<Col className='text-center'>
								{/*<Pie
                                data={state}
                                options={{
                                  title:{
                                    display:true,
                                    text:'',
                                    fontSize:20
                                  },
                                  legend:{
                                    display:true,
                                    position:'right'
                                  }
                                }}
                            />*/}
								<img src='https://collegepass.s3.ap-south-1.amazonaws.com/pieChart.png' className='pieimg' />
							</Col>
						</Row>
						<Row className='bg-white pt-3 pb-3 mt-5 border'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h5 className='text-center color-black secheadng'>
									Accuracy on Math Topics
								</h5>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<canvas id='chart4' width='100%' height='300px'></canvas>
							</Col>
						</Row>
						<Row className='bg-white pt-3 pb-3 mt-5 border'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h5 className='text-center color-black secheadng'>
									Math Section Accuracy
								</h5>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<canvas id='chart3' width='100%' height='300px'></canvas>
							</Col>
						</Row>
						<Row className='bg-black text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-white'>
											Mistakes made on Maths (No Calculator) Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{dbData.mathNocalci !== undefined
														? dbData.mathNocalci.wrongQues.toString()
														: 'loading'}{' '}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row className='bg-black text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-white'>
											Mistakes made on Maths (Calculator) Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{dbData.mathCalci !== undefined
														? dbData.mathCalci.wrongQues.toString()
														: 'loading'}{' '}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row className='bg-gray text-center pt-3 pb-3 mistakes'>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={12}>
										<h2 className='mheading color-black'>
											Topics to focus on Math Section
										</h2>
									</Col>
								</Row>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Row>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
									<Col lg={10} md={10} sm={12} xs={12}>
										<Row>
											<Col lg={12} className='table-bg-gray color-black p-2 word-break'>
												<p className='mb-0'>
													{dbData.mathCalci !== undefined
														? dbData.mathCalci.topic.toString()
														: 'loading'}{' '}
												</p>
											</Col>
										</Row>
									</Col>
									<Col lg={1} md={1} sm={12} xs={12}></Col>
								</Row>
							</Col>
						</Row>
						<Row
							className='footer-e mt-5 bg-black pt-3 pb-3'
							style={{
								borderBottom: '1px solid #ffffff',
							}}
						>
							<Col>
								<Row>
									<Col lg={6} md={6} sm={12} xs={12}>
										<p className='mb-0 text-center color-white'>
											<i className='fa fa-map-marker' aria-hidden='true'></i>
										</p>
										<p className='text-center color-white'>
											3 Shenton Way, #10-05,
											<br />
											Shenton House, Singapore, 068805
										</p>
									</Col>
									<Col lg={6} md={6} sm={12} xs={12}>
										<p className='mb-0 text-center color-white'>
											<i className='fa fa-envelope-o' aria-hidden='true'></i>
										</p>
										<p className='text-center color-white'>
											support@collegpass.org
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col></Col>
				</Row>
			</Container>
			<Footer />
		</div>
	)
}

export default EmailTemplateInternal
