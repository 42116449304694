/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './confirmModal.css';

const ConfirmModal = ({show, handleClose,onsubmit}) => {

  
  return (
        <div className="wrap-modal">
            <Modal className="sure-modal" show={show} onHide={handleClose}>
                <Modal.Header className="text-center" style={{
                    border: 'none'
                }}>
                    <Modal.Title style={{
                        width: '100%',
                        
                    }}>Are you sure you want to submit the answers?</Modal.Title>
                </Modal.Header>
                {/*<Modal.Body>Click Confirm If you want to submit the test now, or Click Cancel</Modal.Body>*/}
                <Modal.Footer style={{
                    border: 'none'
                }}>
                <Button variant="primary" type="submit" onClick={onsubmit}>Submit</Button>
                <Button variant="primary" onClick={handleClose}>
                    Cancel
                </Button>
                </Modal.Footer>
            </Modal>
        </div>    
  );
}

export default ConfirmModal;
