import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import {authReducer} from './reducers/authReducers'
import {alertReducer} from './reducers/alertReducers'
import {testReducer} from './reducers/testReducers'
const reducer = combineReducers({
	auth: authReducer,
	alert: alertReducer,
	test: testReducer,
})

const middleware = [thunk]

const store = createStore(
	reducer,
	composeWithDevTools(applyMiddleware(...middleware))
)

export default store
