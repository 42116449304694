export const tokenRefreshAPI =
	'https://api.collegepass.org/api/v1/auth/getUserDetailByToken'

export const APIregister = 'https://api.collegepass.org/api/v1/auth/register'
export const signInAPI = 'https://api.collegepass.org/api/v1/auth/login'
export const checkTestGivenStatusAPI =
	'https://api.collegepass.org/tp/v1/checkUserTestGivenStatus'
export const initialiseTestAPI =
	'https://api.collegepass.org/tp/v1/initializeTest'

export const getFinalResultsAPI =
	'https://api.collegepass.org/tp/v1/getFinalResult'

export const requestFullReport =
	'https://api.collegepass.org/tp/v1/requestFinalReport'

export const storeUserAnswers =
	'https://api.collegepass.org/tp/v1/storeUserAnswers'

export const registerAPI = 'https://api.collegepass.org/api/v1/auth/register'

export const emailReportAPI =
	'https://api.collegepass.org/tp/v1/getDetailedReport'
