import { Container, Row, Col, Image } from 'react-bootstrap';
import './footer.css';

const Footer = () => {

  return (
            <div className="footer-wrap">
                <Container fluid>
                    <Row>
                        <Col className="footer bg-black pt-3 pb-0">
                            <Row className="pl-3 logo-footer">
                                <Col lg={2} md={2} sm={2} xs={2} className="display-content">
                                    <p className="mr-3"><Image src="https://collegepass-logos.s3.ap-south-1.amazonaws.com/holo.png" alt="CP-Logo" className="fholo mr-2" />
                                    </p><p className="mr-3 color-white border opacity-6"></p>
                                    <p className="mr-3 copy-sec-img"><Image src="https://collegepass-logos.s3.ap-south-1.amazonaws.com/chaseivy_logo.svg" alt="CP-Logo"  className="flogo" />
                                    </p>
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={12}>
                                    <p className="color-white opacity-6 copyright">© 2020 Empagnie PTE Limited</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>  
            </div>
  );
}

export default Footer;
