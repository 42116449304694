import {
	INITIALISE_TEST_START,
	INITIALISE_TEST_COMPLETED,
	INITIALISE_TEST_ERROR,
} from '../constants/testConstants'
import {reactLocalStorage} from 'reactjs-localstorage'
import {testDetails} from '../config/localStorage'
const initialState = {
	testDetails: reactLocalStorage.getObject(testDetails, {}, true),
}

export const testReducer = (state = initialState, action) => {
	const {type, payload} = action

	switch (type) {
		case INITIALISE_TEST_START:
			return {
				...state,
				testDetails: payload.testDetails,
			}
		case INITIALISE_TEST_COMPLETED:
			return {
				...state,
				testDetails: payload.testDetails,
			}
		case INITIALISE_TEST_ERROR:
			return {
				...state,
				testDetails: payload.testDetails,
			}
		default:
			return state
	}
}
